export const queriesPreviewFallback: Record<string, any> = {
  en: [
    {
      query: 'precomendado_eu',
      title: 'Our pharmaceutical recommendation',
    },
    {
      query: 'precomendado_cos_eu',
      title: 'Dermocosmetics and body care',
    },
    {
      query: 'precomendado_nutri_eu',
      title: 'Health and well-being',
    },
  ],
  pt: [
    {
      query: 'precomendado_pt',
      title: 'Nossa recomendação farmacêutica',
    },
    {
      query: 'precomendado_cos_pt',
      title: 'Dermocosmética e cuidado corporal',
    },
    {
      query: 'precomendado_nutri_pt',
      title: 'Saúde e bem-estar',
    },
  ],
  es: [
    {
      query: 'precomendado_es',
      title: 'Nuestra recomendación farmacéutica',
    },
    {
      query: 'precomendado_cos_es',
      title: 'Dermocosmética y cuidado corporal',
    },
    {
      query: 'precomendado_nutri_es',
      title: 'Salud y bienestar',
    },
  ],
}
