<template>
  <div class="x" data-test="x" :dir="documentDirection">
    <SnippetConfigExtraParams :excluded-extra-params="excludedExtraParams" />
    <SnippetCallbacks />
    <Tagging :storage-t-t-l-ms="storageTTLMs" />
    <UrlHandler />
    <MainModal v-if="isOpen" data-wysiwyg="layer" />
  </div>
</template>

<script lang="ts">
import type { SnippetConfig, UrlParams, XEvent, XEventsTypes } from '@empathyco/x-components'
import type { QueryPreviewInfo } from '@empathyco/x-components/queries-preview'
import type { InternalSearchRequest, InternalSearchResponse } from '@empathyco/x-components/search'
import type { Result } from '@empathyco/x-types'
import type { ComputedRef, Ref } from 'vue'
import { SnippetCallbacks, useXBus } from '@empathyco/x-components'
import { SnippetConfigExtraParams } from '@empathyco/x-components/extra-params'
import { Tagging } from '@empathyco/x-components/tagging'
import { UrlHandler } from '@empathyco/x-components/url'
import {
  computed,
  defineAsyncComponent,
  defineComponent,
  getCurrentInstance,
  inject,
  onBeforeUnmount,
  onMounted,
  provide,
  ref,
  watch,
} from 'vue'
import { useDevice } from './composables/use-device.composable'
import currencies from './i18n/currencies'
import { queriesPreviewFallback } from './x-components/queries-preview.options'
import './tailwind/index.css'

export default defineComponent({
  components: {
    SnippetCallbacks,
    SnippetConfigExtraParams,
    Tagging,
    UrlHandler,
    MainModal: defineAsyncComponent(() =>
      import('./components/custom-main-modal.vue').then(m => m.default),
    ),
  },
  setup() {
    const storageTTLMs = 604800000 //1 week
    const xBus = useXBus()
    const appInstance = getCurrentInstance()
    const { deviceName } = useDevice()
    const snippetConfig = inject<SnippetConfig>('snippetConfig')!
    const isOpen = ref(false)
    const add2CartResult: Ref<Result | undefined> = ref(undefined)
    const showNextQueriesTags: Ref<boolean> = ref(true)

    const openXEvents = ['UserOpenXProgrammatically', 'UserClickedOpenX']

    const showNextQueries = computed(() => showNextQueriesTags.value)
    provide<Ref<boolean>>('showNextQueries', showNextQueries)

    const add2CartCallbackEvents: XEvent[] = [
      'UserClickedResultAddToCart',
      'UserClickedResultRemoveFromCart',
    ]

    const excludedExtraParams = [
      'callbacks',
      'userName',
      'uiLang',
      'consent',
      'documentDirection',
      'currency',
      'filters',
      'isolate',
      'queriesPreview',
      'cart',
      'wishlist',
    ]

    const open = (): void => {
      isOpen.value = true
      window.wysiwyg?.open()
    }

    openXEvents.forEach(event => xBus.on(event as XEvent, false).subscribe(open))

    const close = (): void => {
      window.wysiwyg?.close()
    }

    xBus.on('UserClickedCloseNextQueries', false).subscribe(() => {
      showNextQueriesTags.value = false
    })
    xBus.on('UserAcceptedAQuery', false).subscribe(() => {
      showNextQueriesTags.value = true
    })
    xBus.on('UserClickedCloseX', false).subscribe(close)

    xBus.on('UserAcceptedAQuery', false).subscribe(async (query): Promise<void> => {
      if (/^::\s*login/.test(query)) {
        await window.wysiwyg?.goToLogin()
      }
    })

    xBus
      .on('SearchRequestChanged', false)
      .subscribe((payload: InternalSearchRequest | null): void => {
        window.wysiwyg?.setContext({ query: payload?.query, spellcheckedQuery: undefined })
      })

    xBus.on('SearchResponseChanged', false).subscribe((payload: InternalSearchResponse): void => {
      if (payload.spellcheck) {
        window.wysiwyg?.setContext({ spellcheckedQuery: payload.spellcheck })
      }
    })

    xBus.on('ParamsLoadedFromUrl', false).subscribe(async (payload: UrlParams): Promise<void> => {
      try {
        if (window.wysiwyg) {
          await window.wysiwyg?.requestAuth()
          window.InterfaceX?.search()
          window.wysiwyg?.setContext({ query: payload.query })
        }
      } catch {
        // No error handling
      }
    })

    const documentDirection = computed(() => {
      return (
        document.documentElement.dir ||
        document.body.dir ||
        (snippetConfig.documentDirection ?? 'ltr')
      )
    })

    const currencyFormat = computed(() => currencies[snippetConfig.currency!])
    provide<string>('currencyFormat', currencyFormat.value)

    const addToCartResult = computed(() => add2CartResult.value)
    provide<Ref<Result | undefined>>('addToCartResult', addToCartResult)

    const queriesPreviewInfo = computed(() =>
      snippetConfig.queriesPreview && snippetConfig.queriesPreview?.length > 0
        ? snippetConfig.queriesPreview
        : (queriesPreviewFallback[snippetConfig.lang] ?? queriesPreviewFallback.en),
    )
    provide<ComputedRef<QueryPreviewInfo[]> | undefined>('queriesPreviewInfo', queriesPreviewInfo)

    watch(
      () => snippetConfig.uiLang,
      uiLang => appInstance?.appContext.config.globalProperties.$setLocale(uiLang ?? 'en'),
    )

    watch(deviceName, device =>
      appInstance?.appContext.config.globalProperties.$setLocaleDevice(device),
    )

    /**
     * When the 'SnippetCallbackExecuted' event is emitted, checks if the events
     * included in the payload is one of the 'add2CartCallbackEvents'.
     * If the callback return is null, it emits 'UserModifiedAdd2cart'
     * and sets 'isOutOfStock' to true.
     *
     * @param payload - The snippet callbacks events that have been executed.
     */
    async function callbackExecuted(
      payload: XEventsTypes['SnippetCallbackExecuted'],
    ): Promise<void> {
      if (add2CartCallbackEvents.includes(payload.event)) {
        const callbackReturn = (await payload.callbackReturn) as {
          id: string
          addToCartEnabled: boolean
        }
        await xBus.emit('UserModifiedAdd2cart', {
          id: (payload.payload as Result).id,
          addToCartEnabled: callbackReturn.addToCartEnabled,
        })
      }
    }
    xBus.on('SnippetCallbackExecuted', false).subscribe(callbackExecuted)

    const reloadSearch = (): void => {
      xBus.emit('ReloadSearchRequested')
    }

    onMounted(() => {
      document.addEventListener('wysiwyg:reloadSearch', () => reloadSearch())
    })

    onBeforeUnmount(() => {
      document.removeEventListener('wysiwyg:reloadSearch', () => reloadSearch())
    })

    return {
      documentDirection,
      excludedExtraParams,
      isOpen,
      storageTTLMs,
    }
  },
})
</script>

<style scoped>
.x-modal:deep(.x-modal__content) {
  width: 100%;
  height: 100%;
  background-color: white;
  overflow: auto;
}
</style>

<style lang="scss">
*:not(.x-keyboard-navigation *) {
  outline: none;
}
.x-promoted {
  height: 100%;
  &__image {
    object-fit: cover !important;
    height: 100%;
  }
}
.x-banner,
.x-promoted {
  &__title {
    display: none;
  }
}
.x-main-modal {
  z-index: 3 !important;
}
</style>
