import type {
  NumberRangeFacet,
  RecommendationsRequest,
  Result,
  SearchResponse,
} from '@empathyco/x-types'

import {
  facetSchema,
  platformAdapter,
  type PlatformFacet,
  type PlatformRecommendationsRequest,
  type PlatformResult,
  type PlatformSearchResponse,
  recommendationsRequestSchema,
  resultSchema,
  searchResponseSchema,
} from '@empathyco/x-adapter-platform'

import { getFacetConfigWithEditable } from './utils/facet.utils'

export const adapter = platformAdapter
interface FarmaciasDirectSearchResponse extends Omit<PlatformSearchResponse, 'catalog'> {
  catalog: Omit<PlatformSearchResponse['catalog'], 'stats'> & {
    stats: {
      precioFinal: {
        min: string
        max: string
      }
    }
  }
}

searchResponseSchema.$override<FarmaciasDirectSearchResponse, Partial<SearchResponse>>({
  stats: ({ catalog }) => ({
    price: {
      min: catalog.stats?.precioFinal?.min ? Number.parseFloat(catalog.stats.precioFinal.min) : 0,
      max: catalog.stats?.precioFinal?.max
        ? Number.parseFloat(catalog.stats.precioFinal.max)
        : 10000,
    },
  }),
})

facetSchema.$override<PlatformFacet, Partial<NumberRangeFacet>>({
  modelName: ({ type, facet }) =>
    getFacetConfigWithEditable(facet, type).modelName as 'NumberRangeFacet' | undefined,
  filters: {
    $path: 'values',
    $subSchema: ({ type, facet }) => getFacetConfigWithEditable(facet, type).schema,
  },
})
/* Code sample about how to extend the result mapper with more fields. */

interface EmpathyDemoPlatformResult extends PlatformResult {
  descripcion: string
  nombre: string
  marca: string
  disponibilidad: string
  externalIdProducto: string
  externalIdVariante: string
  facetas: string[]
  variants: number
}

declare module '@empathyco/x-types' {
  export interface Result {
    title: string
    brand: string
    availability: string
    isOutOfStock: boolean
    sellPackUnit: number
    stock: number
    isPack: boolean
    externalId: string
    variantId: string
    facetas: string[]
    description: string
    numberOfVariants: number
  }
}

resultSchema.$override<EmpathyDemoPlatformResult, Partial<Result>>({
  title: 'nombre',
  brand: 'marca',
  description: 'descripcion',
  availability: 'disponibilidad',
  externalId: 'externalIdProducto',
  variantId: 'externalIdVariante',
  facetas: 'facetas',
  numberOfVariants: 'variants',
  price: {
    value: ({ __prices: rawPrices }) => Number(rawPrices.current.value),
    originalValue: ({ __prices: rawPrices }) =>
      Number(rawPrices.previous?.value ?? rawPrices.current.value),
    futureValue: ({ __prices: rawPrices }) =>
      Number(rawPrices.future?.value ?? rawPrices.current.value),
    hasDiscount: ({ __prices: rawPrices }) =>
      Number(rawPrices.current.value) <
      Number(rawPrices.previous?.value ?? rawPrices.current.value),
  },
  images: ({ __images }) => (Array.isArray(__images) ? __images.reverse() : [__images]),
  isOutOfStock: rawResult => rawResult.disponibilidad !== 'in_stock',
  sellPackUnit: () => 1, //mocking the sellPackUnit,
  stock: () => 99, //mocking the stock,
  isPack: () => false, //mocking the isPack,
})

recommendationsRequestSchema.$override<
  RecommendationsRequest,
  Partial<PlatformRecommendationsRequest>
>({
  // TODO Top clicked demo endpoint breaks if it receives the scope parameter
  extraParams: ({ extraParams: { scope, ...extraParams } = {} }) => extraParams,
})
